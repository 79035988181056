@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@500;800&display=swap);
:root {
  
  /* Main Settings  */
  --main-background-color: rgb(235, 235, 235);

  --text-primary-color: rgb(0, 0, 0);
  --text-secondary-color: rgb(68, 68, 68);

  /* Nav Settings  */
  --nav-link-color: rgb(255, 255, 255);
  --nav-link-hover-color: rgb(87, 87, 87);
  --nav-link-hover-background-color: none;
  --nav-background-color: none;
  --nav-height: 150px;
  --nav-logo-width: 100px;
  --nav-logo-font-size: 20px;

  /* Mobile Nav Settings  */

  --mobile-nav-background-color: rgb(0, 0, 0);
  --mobile-nav-logo-height:150px;
  --mobile-nav-link-color: rgb(65, 65, 65);
  --mobile-nav-link-hover-color: rgb(0, 0, 0);
  --mobile-nav-link-size: 20px;

  /* Card Settings  */
  --card-background-color: rgba(0, 0, 0, 0.747);
  --card-border-radius: 10px;
  --card-shadow: 10px 10px 20px -13px #f8931f;

  /* Minting Area */
  --minting-box-color: #151A1F;
  --minting-box-heading-color: #28cc1c;
  --minting-box-info-box-color: rgb(65, 65, 65);
  --minting-box-text-color: white;


  /* Minting Button */
  --minting-button-color: linear-gradient(180deg,#604ae5 0%,#813eee 100%);
  --minting-button-text-color: white;

}







.App {
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
}
body {
  height: 100vh;
  color: var(--main-text-color);
  position: relative;
  margin: 0;
  font-family: 'Barlow', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: radial-gradient(circle, #f8931f 0%,#28cc1c 30%, #000000 40%);
  font-size: 20px;
}
header h1{
  font-size: 60px;
  font-family: Barlow;
  color: white;
  font-weight: 800;
  letter-spacing: 5px;;
}
header p{
  font-size: 30px;
  color: white!important;
  font-family: Barlow;
  font-weight: 300;
  
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  background: var(--nav-background-color);
  width: 100%;
  height: var(--nav-height);
  transition: height 0.2s ease-in-out;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 70%;
  margin: auto;
}

.nav-logo {
  width: var(--nav-logo-width);
}

nav a {
  font-size: var(--nav-logo-font-size);
  color: var(--nav-link-color);
  text-decoration: none;

  padding: 20px;
}

nav a:hover {
  color: var(--nav-link-hover-color);
  background-color: var(--nav-link-hover-background-color);
}

.mobile-menu-button {
  height: 30px;
  width: 30px;
  position: fixed;
  top: 37px;
  right: -50px;
  transition: right 0.2s ease-in-out;
  z-index: 45;
  cursor: pointer;
}

.open-menu {
  left: 0px !important;
}

.mobile-nav {
  position: fixed;
  min-height: 100vh;
  background: rgb(117, 69, 69);
  background: var(--mobile-nav-background-color, rgb(117, 69, 69));
  left: -100%;
  z-index: 50;
  width: 100%;
  transition: left 0.3s ease;
}

.mobile-nav-close-button {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 37px;
  right: 37px;
  cursor: pointer;
}

.mobile-nav-close-button img {
  height: 100%;
  width: 100%;
}

.mobile-nav ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 40px;
  list-style: none;
}

.mobile-nav li {
  list-style: none;
  padding: 20px;
}

.mobile-nav a {
  text-decoration: none;
  color: var(--mobile-nav-link-color);
  font-size: var(--mobile-nav-link-size);
}

.mobile-nav a:hover {
  color: var(--mobile-nav-link-hover-color);
}

.mobile-nav-logo {
  width: 150px;
  height: 150px;
}

.text-muted {
  color: darkgrey;
}

.nav-social {
  width: 30px;

  padding: 0 10px 0 10px;
}

.social-icons {
  display: flex;
}

@media only screen and (max-width: 800px) {
  .hide-800 {
    display: none;
  }
body{
  height: 120vh;
}
  .mobile-menu-button {
    right: 37px;
  }
  header h1{
    font-size: 40px;
  }

  .nav-logo {
    height: 150px;
    width: 150px;
  }

  nav {
    height: 100px;
  }
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.pb-4 {
  padding-bottom: 40px;
}

header {
  max-width: 100%;
  margin: auto;
  align-items: center;
  border-radius: 30px;
  margin-bottom: 40px;
  display: grid;
  grid-gap: 40px;
  gap: 40px;
  padding: 35px;
  flex-direction: column;
}

.mintHeader {
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

@media only screen and (min-width: 800px) {
  header {
    grid-template-columns: 1fr 1fr;
    padding: 40px;
    grid-gap: 20px;
    gap: 20px;
  }
}

.text-primary-color {
  color: var(--text-primary-color);
}

.text-secondary-color {
  color: var(--text-secondary-color);
}

.team {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  padding: 20px;
  margin: 40px auto;
  max-width: 900px;
}

.team-item {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 20px -13px white;
}

.faq {
  max-width: 900px;
  margin: auto;
  align-items: center;
  padding: 20px;
}

.faq div {
  margin-bottom: 20px;
}

.faq p {
  padding: 20px;
}

.rainbow-gallery {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.rainbow-gallery img {
  width: 100%;
}

.card {
  align-items: center;
  border-radius: var(--card-border-radius);
  padding: 40px;
  background: var(--card-background-color);
  box-shadow: var(--card-shadow);
}

.container {
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
}

.content-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 70%;
  margin: auto;
}

.mints-available {
  background: rgb(65, 65, 65);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-box {
  background-color: var(--minting-box-color) !important;
  color: var(--minting-box-text-color) !important;
}

.minting-box h5 {
  color: var(--minting-box-heading-color);
  font-family: Barlow;
  font-weight: 100!important;
  font-size: 40px;
}

.minting-info {
  background-color: var(--minting-box-info-box-color);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}
.MuiTypography-body1{
  font-family: Barlow!important;
  font-weight: 100!important;
  text-align: center;
}

.minting-info h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.minting-button {
  background: var(--minting-button-color) !important;
}

.custom-mint-container {
  margin-bottom: 20px;
 }



.loading:after {
  content: " .";
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

@-webkit-keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

@media (max-width: 600px)
{
  header{
    display: block;
  }
  header p{
    margin-bottom: 44px;
    font-size: 20px;
    text-align: center;
  }
  header h1{
    text-align: center;
  }
  .custom-mint-container > img{
   height: 171px;
  }
  .card {
    width: 80%;
  }
  .MuiContainer-root{
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
  .MuiButtonBase-root{
    left: -20px!important;
    width: 120%!important;
  }
}
.custom-mint-container img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  box-shadow: 0 5px 15px 0px rgb(0, 0, 0);
	-webkit-transform: translatey(0px);
	        transform: translatey(0px);
	-webkit-animation:  zoom-in-zoom-out 2s ease-out infinite;
	        animation:  zoom-in-zoom-out 2s ease-out infinite;;
}
@-webkit-keyframes zoom-in-zoom-out {
  0% {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  }
  50% {
  -webkit-transform: scale(1.05, 1.05);
          transform: scale(1.05, 1.05);
  }
  100% {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  }
  }
@keyframes zoom-in-zoom-out {
  0% {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  }
  50% {
  -webkit-transform: scale(1.05, 1.05);
          transform: scale(1.05, 1.05);
  }
  100% {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  }
  }
@-webkit-keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		-webkit-transform: translatey(-20px);
		        transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
}
@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		-webkit-transform: translatey(-20px);
		        transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
}
@media(max-width: 600px){
  .MuiTypography-body1{
    margin-right: 50px;
  }
  body {
    height: 100%;
  }
}
.custom-mint-container img{
  max-width:350px;

}
#link1 h1{
  text-align: center;
}
#link1 h1:nth-of-type(2){
  text-align: left;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: normal;
}
#link1 h3{
  position: relative;
  color: #f8931f;
  font-weight: 100;
  text-align: center;
  font-size: 30px;
 margin-top: -20px;
 margin-bottom: 20px;
}
.social-icons{
  justify-content: center;
  align-items: center;
}
nav a{
  color:#28cc1c;
  font-size: 50px; 
}
nav a span{
  color:#f8931f;
}
.nav-logo2{
  width: 300px;
}
#link1 p span{
  font-size: 25px;
}

@media(max-width: 600px){
  .nav-logo{
    width: 50px;
    height: 50px;
  }
  .nav-logo2{
    width: 150px;
  }
  #link1 h1:nth-of-type(2){
    text-align: center;
    font-size: 30px;
  }
}
